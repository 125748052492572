import React from 'react';
import SEO from 'components/seo';
import { GoBackButton, MobileHeading } from 'components/misc';
import CorporateGovernanceLayout from 'layouts/corporate-governance-layout';
import styled from 'styled-components';

const news = [
  {
    id: 4,
    title: '28.12.2020 V'
  },
  {
    id: 3,
    title: '1.12.2020 IV'
  },
  {
    id: 2,
    title: '12.11.2020 III'
  },
  {
    id: 1,
    title: '23.10.2020 II',
  },
  {
    id: 0,
    title: '29.09.2020 I',
  }
];

const Message = styled.div`
  margin-bottom: 50px;
`;

const ParagraphBold = styled.p`
  font-weight: 600;
`;
const Paragraph = styled.p`
  margin: 10px 0;
`;
const Communication = ()=> {
  return (
    <CorporateGovernanceLayout>
      <SEO title="Komunikacja z Akcjonariuszami" />
      <GoBackButton path="/investors" name="Dla akcjonariuszy" />
      <MobileHeading heading="Komunikacja z Akcjonariuszami" />
      {news.map((message)=> {
        return <Message key={message.id}>
          <ParagraphBold>{message.title} WEZWANIE AKCJONARIUSZY SPÓŁKI GRUPA EKOENERGETYKA SPÓŁKA AKCYJNA DO ZŁOŻENIA POSIADANYCH DOKUMENTÓW AKCJI W CELU ICH DEMATERIALIZACJI.</ParagraphBold>
          <Paragraph>Zarząd Spółki pod firmą Grupa Ekoenergetyka Spółka Akcyjna z siedzibą w Warszawie (dalej „Spółka”) w związku z art. 16 ustawy z dnia 30 sierpnia 2019 roku o zmianie ustawy – Kodeks spółek handlowych oraz niektórych innych ustaw (Dz.U. z 2019 r. poz. 1798) wzywa wszystkich akcjonariuszy Spółki do złożenia posiadanych dokumentów akcji w celu ich dematerializacji.</Paragraph>
          <Paragraph>Złożenie dokumentów akcji odbędzie się za pisemnym pokwitowaniem wydanym akcjonariuszowi. Dokumenty akcji należy składać w siedzibie Spółki w Warszawie pod adresem ul. Postępu 14, 02-676 Warszawa, w dniach roboczych (od poniedziałku do piątku) w godzinach 7.00 – 15.00.</Paragraph>
        </Message>;
      })}
    </CorporateGovernanceLayout>
  );
};

export default Communication;
