import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import InvestorsLayout from 'layouts/investors-layout';
import { Col33, Aside, FlexContainer } from 'components/misc';
import { media } from 'utils';
import activeColor from '../style/active-color';

const CorporateGovernanceWrapper = styled(FlexContainer)``;
const StyledCol33 = styled(Col33)`
  ${media.tablet`
    display: none;
  `}
`;

const SubmenuItem = styled.li`
  font-size: 1rem;
  font-weight: normal;
  margin-bottom: 16px;
`;

const MenuItem = styled.li`
  font-size: 2.571rem;
  font-weight: bold;
  margin-bottom: 16px;
`;

const Col66 = styled.div`
  width: 66%;
  padding: 0 16px;

  ${media.tablet`
    width: 100%;
    padding: 0;
  `}
`;

const StyledLink = styled(Link)`
  color: ${({ theme })=> theme.color.gray};
`;

const CorporateGovernanceLayout = ({ children })=> {
  return (
    <InvestorsLayout>
      <CorporateGovernanceWrapper>
        <StyledCol33>
          <div>
            <ul>
              <MenuItem>
                <StyledLink
                  partiallyActive
                  activeStyle={activeColor}
                  to="/investors/financial-documents">
                    Dokumenty finansowe
                </StyledLink>
              </MenuItem>
              <MenuItem>
                <StyledLink
                  partiallyActive
                  activeStyle={activeColor}
                  to="/investors/corporate-governance">
                    Ład Korporacyjny
                </StyledLink>
                <ul>
                  <SubmenuItem>
                    <StyledLink
                      activeStyle={activeColor}
                      to="/investors/corporate-governance">
                    Zarząd
                    </StyledLink>
                  </SubmenuItem>
                  <SubmenuItem>
                    <StyledLink
                      activeStyle={activeColor}
                      to="/investors/corporate-governance/board-of-directors">
                    Rada Nadzorcza
                    </StyledLink>
                  </SubmenuItem>
                  <SubmenuItem>
                    <StyledLink
                      activeStyle={activeColor}
                      to="/investors/corporate-governance/shareholders">
                    Walne Zgromadzenia
                    </StyledLink>
                  </SubmenuItem>
                </ul>
              </MenuItem>
              <MenuItem>
                <StyledLink
                  partiallyActive
                  activeStyle={activeColor}
                  to="/investors/communication">
                    Komunikacja z Akcjonariuszami
                </StyledLink>
              </MenuItem>
            </ul>
          </div>
        </StyledCol33>
        <Col66>
          {children}
        </Col66>
      </CorporateGovernanceWrapper>
    </InvestorsLayout>
  );
};

export default CorporateGovernanceLayout;
