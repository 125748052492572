import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Section, Container, Aside, Col75 } from 'components/misc';
import { Link } from 'gatsby';
import SEO from 'components/seo';
import { media } from 'utils';
import activeColor from '../style/active-color';

const FlexContainer = styled(Container)`
  display: flex;

  @media (max-width: ${({ theme })=> theme.breakpoints.width.tablet}) {
    flex-direction: column;
  }
`;

const StyledAside = styled(Aside)`

  ${media.tablet`
    display: ${({ isVisible })=> isVisible ? 'block' : 'none'};
    > div {
      opacity: 1;
    }
  `}
`;

const StyledH3 = styled.h3`
  font-size: 1rem;
  margin: 15px 0;

  ${media.tablet`
    color: ${(({ theme })=> theme.color.green)};
    font-size: 1.285rem;
  `}
`;

const StyledLi = styled.li`
`;

const InvestorsLayout = ({ children })=> {
  const [isInvestorsRoot, setIsInvestorsRoot] = useState(true);

  useEffect(()=> {
    setIsInvestorsRoot(location.pathname === '/investors' || location.pathname === '/investors/');
  }, []);

  return (
    <>
      <SEO title="Inwestorzy" />
      <Section topSection>
        <FlexContainer>
          <StyledAside isVisible={isInvestorsRoot} title="Inwestorzy" withTitleAndShortcut titleAsLink linkRef="/investors">
            <StyledH3>
              <Link activeStyle={activeColor} to="/investors/financial-documents">Dokumenty finansowe</Link>
            </StyledH3>
            <StyledH3>
              <Link partiallyActive activeStyle={activeColor} to="/investors/corporate-governance">
              Ład Korporacyjny
              </Link>
            </StyledH3>
            <ul>
              <StyledLi><Link activeStyle={activeColor} to="/investors/corporate-governance">Zarząd</Link></StyledLi>
              <StyledLi><Link activeStyle={activeColor} to="/investors/corporate-governance/board-of-directors">Rada Nadzorcza</Link></StyledLi>
              <StyledLi><Link activeStyle={activeColor} to="/investors/corporate-governance/shareholders">Walne Zgromadzenia</Link></StyledLi>
            </ul>
            <StyledH3>
              <Link partiallyActive activeStyle={activeColor} to="/investors/communication">
              Komunikacja z Akcjonariuszami
              </Link>
            </StyledH3>
          </StyledAside>
          <Col75>
            {children}
          </Col75>
        </FlexContainer>
      </Section>
    </>
  );
};

export default InvestorsLayout;
